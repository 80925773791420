import { render } from "./downloadNurse.vue?vue&type=template&id=eeccc828&scoped=true"
import script from "./downloadNurse.vue?vue&type=script&lang=js"
export * from "./downloadNurse.vue?vue&type=script&lang=js"

import "./downloadNurse.vue?vue&type=style&index=0&id=eeccc828&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-eeccc828"
/* hot reload */
if (module.hot) {
  script.__hmrId = "eeccc828"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('eeccc828', script)) {
    api.reload('eeccc828', script)
  }
  
  module.hot.accept("./downloadNurse.vue?vue&type=template&id=eeccc828&scoped=true", () => {
    api.rerender('eeccc828', render)
  })

}

script.__file = "src/views/static/about/downloadNurse.vue"

export default script